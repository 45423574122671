<script context="module">
  export async function postFile(url = '', params = {}, data = '') {
    let urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'audio/wave',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data, // body data type must match "Content-Type" header
    })

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function postFileHeaders(
    url = '',
    headers = {},
    params = {},
    data = ''
  ) {
    let urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    let formData = new FormData()
    formData.append('content', data)

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data, // body data type must match "Content-Type" header
    })

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function postJsonFile(
    url = '',
    headersValue = {},
    params = {},
    data = {}
  ) {
    var urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: headersValue,
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function postJsonFileGetText(
    url = '',
    headersValue = {},
    params = {},
    data = {}
  ) {
    var urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: headersValue,
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    return response.text() // parses JSON response into native JavaScript objects
  }

  export async function getDataOutside(url = '') {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error('Bad response from server status:' + response.status)
    }

    return response.json() // parses JSON response into native JavaScript objects
  }

  export function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime() //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16 //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  export function setCookie(cname, cvalue, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  export function getCookie(cname) {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  export function mapPronToIpa(ph) {
    const mapTable = {
      B: 'b',
      CH: 't.ʃ',
      D: 'd',
      DH: 'ð',
      DX: 'ɾ',
      F: 'f',
      G: 'g',
      H: 'h',
      HH: 'h',
      JH: 'd.ʒ',
      K: 'k',
      L: 'l',
      M: 'm',
      N: 'n',
      NG: 'ŋ',
      NX: 'ɾ̃',
      P: 'p',
      R: 'ɻ',
      S: 's',
      SH: 'ʃ',
      T: 't',
      TH: 'θ',
      V: 'v',
      W: 'w',
      WH: 'ʍ',
      J: 'j',
      Z: 'z',
      ZH: 'ʒ',
      AA: 'ɑ',
      AE: 'æ',
      AH0: 'ə',
      AH: 'ʌ',
      AO: 'ɔ',
      AOX: 'ɔ.ə',
      AU: 'ɑ.ʊ',
      AX: 'ə',
      AXR: 'ɚ',
      AW: 'aʊ',
      AI: 'ɑ.ɪ',
      AW: 'aʊ',
      AY: 'ɑ.ɪ',
      EH: 'ɛ',
      EHX: 'ɛ.ə',
      ER: 'ɜ',
      EL: 'l̩',
      EM: 'm̩',
      EN: 'n̩',
      ERR: 'ɝ',
      EI: 'e.ɪ',
      EY: 'e.ɪ',
      IH: 'ɪ',
      I: 'i',
      IY: 'i',
      IH: 'ɪ',
      O: 'o',
      OI: 'ɔ.ɪ',
      OY: 'ɔ.ɪ',
      OW: 'əʊ',
      OWX: 'o.ə',
      Q: 'ɒ',
      UH: 'ʊ',
      U: 'u',
      UW: "uː",
      UX: 'ʉ',
      UWX: 'u.ə',
      Y: 'j',
    }
    const p = ph.toUpperCase()
    return mapTable[p] ? mapTable[p] : p
  }
</script>
