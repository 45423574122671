import { writable } from 'svelte/store';

// export const BASE_URL = "http://localhost:8088/"
// export const BASE_URL = "https://sensay.tw/"
export const BASE_URL = "https://api3.oksensay.com/"

export const compatibility = writable(true);

export const termsAndConditionOpen = writable(false);
export const privacyPolicyOpen = writable(false);

export const recordingButtonState = writable("");

export const lessonList = writable([]);

export const lessonPos = writable(0);

export const userDetail = writable({userName: undefined, loginToken: undefined})

export const apiToken = writable("");