<script>
  import { createEventDispatcher, onMount } from "svelte";

  let recordings = [];
  let recordAudio = [];
  let recordCount = 0;

  onMount(async () => {
    checkPermissions();
  });

  const workerOptions = {
    OggOpusEncoderWasmPath:
      "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm",
    WebMOpusEncoderWasmPath:
      "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm",
  };

  window.MediaRecorder = OpusMediaRecorder;
  let recorder;

  // shim for AudioContext when it's not avb.
  let AudioContext = window.AudioContext || window.webkitAudioContext;
  let audioContext; //audio context to help us record

  let lastFilename;
  let lastBlob;
  let lastAudio;

  const time = 30;
  let remainingTime = time;
  let countDownTimerObject;
  let countDownTimerText = "0:30";

  export let recordingButtonState = "starting";

  let permissionCheck = false;

  const dispatch = createEventDispatcher();

  function checkPermissions() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        console.log("checkPermissions", device);
        if (device?.deviceId != "") {
          permissionCheck = true;
        }
      });

      if (!permissionCheck) {
        console.error("No audio device detected - no permissions");
      }
    });
  }

  function startRecording() {
    remainingTime = time;
    countDownTimerText = "0:" + (remainingTime + "").padStart(2, "0");

    countDownTimerObject = setInterval(function () {
      var c = remainingTime--;

      if (remainingTime < 0) {
        stopRecording();
        countDownTimerText = "Finished";
      } else {
        countDownTimerText = "0:" + (c + "").padStart(2, "0");
      }
    }, 1000);

    navigator.mediaDevices
      .getUserMedia({ audio: { channelCount: 1 }, video: false })
      .then((stream) => {
        let options = { mimeType: "audio/ogg;codecs=opus" };
        // Start recording
        recorder = new MediaRecorder(stream, options, workerOptions);
        recorder.start();
        // Set record to <audio> when recording will be finished
        recorder.addEventListener("dataavailable", (e) => {
          console.log("dataavailable", e);

          createDownloadLink(e.data);
        });
        console.log("Recording started");
        recordingButtonState = "recording";
        checkPermissions();
      })
      .catch(function (err) {
        //enable the record button if getUserMedia() fails
        checkPermissions();
        recordingButtonState = "error";
        console.error("error processing audio", err);
      });
  }

  function stopRecording() {
    console.log("stopButton clicked");
    //disable the stop button, enable the record too allow for new recordings
    recordingButtonState = "stop";

    try {
      clearInterval(countDownTimerObject);

      recorder.stop();
      // Remove “recording” icon from browser tab
      recorder.stream.getTracks().forEach((i) => i.stop());
    } catch (error) {
      console.error("Error stopRecording", error);
    }
  }

  function createDownloadLink(blob) {
    let url = URL.createObjectURL(blob);
    lastBlob = blob;
    //var au = document.createElement('audio');
    //var li = document.createElement('div');

    //name of .wav file to use during upload and download (without extendion)
    let filename = new Date().toISOString();
    lastFilename = filename;

    const newReocrding = { blob: blob, filename: filename, url: url };
    dispatch("message", newReocrding);
  }

  function clickSubmitCalculate() {
    console.log("clickSubmitCalculate");
  }
</script>

<!-- {#if !permissionCheck}
  <div class="mb-1">
    <i class="fas fa-exclamation-circle mr-1" />Please check if microphone is
    enabled
  </div>
{/if} -->
{#if recordingButtonState === "starting" && !recordCount}
  <button
    class="btn btn-primary"
    on:click={startRecording}
    type="button"
    style="font-size: 32px;"
    ><i
      class="fas fa-microphone-alt"
      style="padding: 0px;padding-right: 8px;font-size: 32px;"
    />Record</button
  >
{:else if recordingButtonState === "stop"}
  <button
    class="btn btn-primary"
    on:click={startRecording}
    type="button"
    style="font-size: 32px;"
    ><i
      class="fas fa-microphone-alt"
      style="padding: 0px;padding-right: 8px;font-size: 32px;"
    />Try again</button
  >
{:else if recordingButtonState === "recording"}
  <button
    class="btn btn-danger"
    on:click={stopRecording}
    type="button"
    style="font-size: 32px;"
    ><i
      class="fas fa-microphone-alt"
      style="padding: 0px;padding-right: 8px;font-size: 32px;"
    />Stop - ({countDownTimerText})</button
  >
{:else if recordingButtonState === "error"}
  <i class="fas fa-exclamation-circle mr-1" />Please enable microphone on your browser or device in order to record.
{:else}
  N/A - {recordingButtonState}
{/if}

{#if recordCount > 0}
  <button
    class="btn btn-success"
    on:click={clickSubmitCalculate}
    type="button"
    style="font-size: 32px;"
    ><i
      class="fas fa-spell-check"
      style="padding: 0px;padding-right: 8px;font-size: 32px;"
    />Submit</button
  >
{/if}
