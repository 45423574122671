<script context="module">
    
import { BASE_URL, userDetail, apiToken } from "../../DataStore";
import { get } from 'svelte/store';

import ky from "ky";

export async function getNewTokenFn(force = false) {
    try {
      let apiTokenValue = await ky
        .get(`${BASE_URL}public/api/azureToken3${force? '?force=true': ''}`, {
          retry: {
            limit: 3,
            statusCodes: [400, 429, 502],
          },
          timeout: 30000,
        })
        .text();

        apiToken.set(apiTokenValue)
        console.log('SetToken', get(apiToken))
    } catch (error) {
      processingStatus = "failure";
      let errCode = error?.response?.status;
      let errMsg = await error?.response?.text();
      let errPayload = { code: errCode, msg: errMsg, response: error.response, error: JSON.stringify(error, Object.getOwnPropertyNames(error)) };
      ky.post(BASE_URL + "public/api/log-action", {
        json: {
          source: "S",
          userInfo: get(userDetail).userName,
          action: "TOKEN",
          actionStep: "ERROR",
          msg: JSON.stringify(errPayload),
        },
      });
    }
  }
  </script>