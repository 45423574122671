<script>
  import { createEventDispatcher, onMount } from "svelte";
  import {
    postFileHeaders,
    postJsonFile,
    postJsonFileGetText,
  } from "../Utils.svelte";
  import { BASE_URL } from "../DataStore";
  import { similarity } from "../utilities/SimilarityUtils.svelte";
  import { cleanCharactersForSpeechToText } from "../utilities/FormatUtils.svelte";
  import ky from "ky";
  import { lessonList, lessonPos, userDetail, apiToken } from "../DataStore";
  import { getNewTokenFn } from "./Upload/UploadUtils.svelte";

  export let exerciseDetail;
  export let lastRecording;
  export let loginDetail;

  let processingStatus = "new";
  let processResultData;
  let processResultData2;

  let fileName;
  let sensayData;

  const dispatch = createEventDispatcher();

  let soundEffect;

  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  onMount(async () => {
    soundEffect = new Howl({
      src: ["./assets/audio/prel_musical_65.mp3"],
      volume: 0.5,
      preload: true,
    });
  });

  async function submitResult() {
    let startTime = performance.now();
    console.log("submitResult", lastRecording, exerciseDetail, loginDetail);
    if (!lastRecording) {
      return;
    }
    processingStatus = "processing";
    await getNewTokenFn();
    await uploadMedia();
    await callAzure();
    await callSensayApi();

    let endTime = performance.now();
    ky.post(BASE_URL + "public/api/log-action", {
      json: {
        source: "S",
        userInfo: $userDetail.userName,
        action: "UP2",
        actionStep: "OK",
        duration: endTime - startTime,
        msg: `Key: ${exerciseDetail?.key}`,
      },
    });
  }

  async function uploadMedia() {
    try {
      fileName = "R_" + exerciseDetail?.key + "_" + lastRecording.filename;

      let fileParams = {
        uploadType: "media",
        name: "recordings/" + fileName + ".wav",
        key: "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o",
      };

      const searchParams = new URLSearchParams();
      searchParams.set("uploadType", "media");
      searchParams.set("name", "recordings/" + fileName + ".wav");
      searchParams.set("key", "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o");

      let urlValue = new URL(
        "https://storage.googleapis.com/upload/storage/v1/b/shuoshuo/o",
      );
      urlValue.search = new URLSearchParams(searchParams).toString();

      await ky
        .post(urlValue, {
          body: lastRecording.blob,
          headers: { "Content-Type": "audio/wave" },
          retry: {
            limit: 3,
            statusCodes: [400, 401, 429, 502],
          },
          timeout: 120000,
        })
        .then((data) => {
          console.log("postFile", data);
        });
    } catch (error) {
      processingStatus = "failure";
      let errCode = error?.response?.status;
      let errMsg = await error?.response?.text();
      let errPayload = {
        code: errCode,
        msg: errMsg,
        response: error.response,
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      };
      ky.post(BASE_URL + "public/api/log-action", {
        json: {
          source: "S",
          userInfo: $userDetail.userName,
          action: "UPLOAD_FILE",
          actionStep: "ERROR",
          msg: JSON.stringify(errPayload),
        },
      });
    }
  }

  async function callAzure() {
    try {
      let referenceText = "";
      if (exerciseDetail.type === "READ") {
        referenceText = exerciseDetail.text;
      } else if (exerciseDetail.type === "SELF_PRACTICE") {
        if (exerciseDetail?.selfPracticeText) {
          referenceText = exerciseDetail?.selfPracticeText;
        } else {
          referenceText = "";
        }
      } else if (
        exerciseDetail.type === "QUESTION" ||
        exerciseDetail.type === "VIDEO" ||
        exerciseDetail.type === "IMAGE"
      ) {
        if (exerciseDetail.answer) {
          referenceText = exerciseDetail.answer;
        }
      } else if (exerciseDetail.type === "MULTI_CHOICE") {
        //FIXME - correct way to get correct answer text
        referenceText = exerciseDetail.multiChoiceCorrect;
      } else if (exerciseDetail.type === "READ_IMAGE") {
        if (exerciseDetail.answer) {
          referenceText = exerciseDetail.answer;
        } else {
          referenceText = exerciseDetail.question;
        }
      } else if (exerciseDetail.type === "QUESTION_FIX_ANSWER") {
        //FIXME - correct way to get correct answer text
        referenceText = exerciseDetail.answer;
      }

      if (
        !(
          exerciseDetail?.language === "en-US" ||
          exerciseDetail?.language === "en-us"
        )
      ) {
        referenceText = "";
      } else {
        //FIXME - how to transform other languages
        referenceText = cleanCharactersForSpeechToText(referenceText);
      }

      let pronunciationAssesment = {
        GradingSystem: "HundredMark",
        Granularity: "Phoneme",
        Dimension: "Comprehensive",
        // ReferenceText: unescape(encodeURIComponent(referenceText)),
        ReferenceText: referenceText,
        EnableMiscue: "True",
      };

      console.log("pa", pronunciationAssesment);

      let pa = btoa(JSON.stringify(pronunciationAssesment));
      let headers = {
        //"Ocp-Apim-Subscription-Key": "700c5efafb2b42aca8bc1dfcf11e024e",
        Authorization: `Bearer ` + $apiToken,
        "Pronunciation-Assessment": pa,
        "Content-Type": "audio/wav",
      };
      let params = {
        language: "en-US",
        format: "detailed",
      };
      params.language = exerciseDetail.language;

      await ky
        .post(
          "https://eastasia.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=en-US&format=detailed",
          {
            body: lastRecording.blob,
            headers: headers,
            retry: {
              limit: 3,
              statusCodes: [400, 401, 429, 502],
              maxRetryAfter: 120000,
            },
            timeout: 120000,
            hooks: {
              beforeRetry: [
                async ({ request, options, error, retryCount }) => {
                  let errCode = error?.response?.status;
                  let errMsg = await error?.response?.text();
                  let errPayload = {
                    retryCount: retryCount,
                    code: errCode,
                    msg: errMsg,
                    response: error.response,
                    error: JSON.stringify(
                      error,
                      Object.getOwnPropertyNames(error),
                    ),
                    token: $apiToken,
                  };
                  ky.post(BASE_URL + "public/api/log-action", {
                    json: {
                      source: "S",
                      userInfo: $userDetail.userName,
                      action: "AZURE",
                      actionStep: "RETRY",
                      msg: JSON.stringify(errPayload),
                    },
                  });
                  await getNewTokenFn(true);
                },
              ],
            },
          },
        )
        .json()
        .then((data) => {
          console.log("Azure results", data);
          processResultData = data;
        });
    } catch (error) {
      processingStatus = "failure";
      let errCode = error?.response?.status;
      let errMsg = await error?.response?.text();
      let errPayload = {
        code: errCode,
        apiToken: $apiToken,
        msg: errMsg,
        response: error.response,
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      };
      ky.post(BASE_URL + "public/api/log-action", {
        json: {
          source: "S",
          userInfo: $userDetail.userName,
          action: "AZURE",
          actionStep: "ERROR",
          msg: JSON.stringify(errPayload),
        },
      });
    }
  }

  async function callSensayApi() {
    if (processResultData?.RecognitionStatus != "Success") {
      console.error("error processing", processResultData);
      processingStatus = "failure";
      return;
    }

    sensayData = prepareSensayData();

    await postJsonFile(
      BASE_URL + "public/api/publicRecordings",
      { "Content-Type": "application/json" },
      {},
      sensayData,
    )
      .then((data) => {
        console.log("send data to sensay", data);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
        ky.post(BASE_URL + "public/api/log-action", {
          json: {
            source: "S",
            userInfo: $userDetail.userName,
            action: "API",
            actionStep: "ERROR",
            msg: JSON.stringify(error),
          },
        });
      });

    dispatch("message", {
      text: "Submitted",
      processResultData: sensayData,
    });
    processingStatus = "finished";

    soundEffect.play();

    callHooks();
  }

  function prepareSensayData() {
    let expectedAnswer = exerciseDetail.answer;
    if (exerciseDetail?.type === "READ") {
      expectedAnswer = exerciseDetail?.text;
    } else if (exerciseDetail?.type === "SELF_PRACTICE") {
      expectedAnswer = exerciseDetail?.selfPracticeText;
    } else {
      expectedAnswer = exerciseDetail?.answer;
    }
    if (!expectedAnswer) {
      expectedAnswer = "";
    }

    let similarityValue = 1;
    if (expectedAnswer && expectedAnswer != "") {
      similarityValue = similarity(
        expectedAnswer,
        processResultData.NBest[0]?.Display,
      );
    }

    let accuracyScore = processResultData.NBest[0]?.AccuracyScore.toFixed(2);
    let fluencyScore = processResultData.NBest[0]?.FluencyScore.toFixed(2);
    let completenessScore =
      processResultData.NBest[0]?.CompletenessScore.toFixed(2);
    let pronScore = processResultData.NBest[0]?.PronScore.toFixed(2);
    let similarityScore = Math.round(similarityValue * 100).toFixed(2);

    if (completenessScore > 80 && similarityScore <= 80) {
      similarityScore = completenessScore;
      similarityValue = completenessScore / 100;
    }
    //let wordCount = processResultData.NBest[0]?.Words?.length;

    let wordCount = processResultData.NBest[0]?.Words?.filter(
      (word) =>
        word.ErrorType == "None" ||
        word.ErrorType == "Insertion" ||
        word.ErrorType == "Mispronunciation",
    ).filter((word) => word.AccuracyScore > 0).length;

    let wordPerMinute = 0;
    if (processResultData.Duration > 0) {
      wordPerMinute =
        (60 * wordCount) / (processResultData.Duration / 10000000);
    }

    let durationSecond = Number(processResultData?.Duration / 10000000);

    processResultData.NBest[0]?.Words?.forEach((word) => {
      try {
        const arr = [...word?.Phonemes?.map((p) => p.AccuracyScore)];
        word["SensayWordScore"] = Math.round(average(arr));
      } catch (error) {
        console.error("Error calculating SensayWordScore", error);
        word["SensayWordScore"] = 0;
      }
    });

    //let overallScoreValue = calculateOverallScore(exerciseDetail?.type, exerciseDetail, processResultData);

    let overallScoreValue = (
      (processResultData.NBest[0]?.AccuracyScore +
        Math.round(similarityValue * 100) +
        processResultData.NBest[0]?.FluencyScore) /
      3.0
    ).toFixed(2);

    let scoringResult = calculateOverallScore(
      exerciseDetail,
      processResultData,
      similarityValue,
      wordCount,
      wordPerMinute,
      durationSecond,
    );

    overallScoreValue = scoringResult?.score;
    processResultData["scoringReasons"] = scoringResult?.reasons;

    let sensayData = {
      exerciseKey: exerciseDetail?.key,
      exerciseType: exerciseDetail?.type,
      studentToken: $userDetail.loginToken,
      studentName: $userDetail.userName,
      fileName: fileName,
      data: processResultData,
      similarityValue: similarityScore,
      accuracyScore: accuracyScore,
      accuracyScoreLabel: calculateScoreLabel(accuracyScore),
      fluencyScore: fluencyScore,
      fluencyScoreLabel: calculateScoreLabel(fluencyScore),
      completenessScore: completenessScore,
      completenessScoreLabel: calculateScoreLabel(completenessScore),
      pronScore: pronScore,
      pronScoreLabel: calculateScoreLabel(pronScore),
      wordCount: wordCount,
      wordPerMinute: wordPerMinute,
      overallScore: overallScoreValue,
      overallScoreLabel: calculateScoreLabel(overallScoreValue),
      durationSecond: durationSecond,
    };
    if (processResultData2) {
      sensayData.data["extra"] = processResultData2;
      console.log(999, sensayData, processResultData2);
    }
    return sensayData;
  }

  function calculateOverallScore(
    exerciseDetail,
    processResultData,
    similarityValue,
    wordCount,
    wordPerMinute,
    durationSecond,
  ) {
    let exerciseDetailData = JSON.parse(exerciseDetail.data);

    console.log(555, exerciseDetailData);
    let overallScore = (
      (processResultData.NBest[0]?.AccuracyScore +
        Math.round(similarityValue * 100) +
        processResultData.NBest[0]?.FluencyScore) /
      3.0
    ).toFixed(2);
    let reasons = [];

    if (
      ["QUESTION", "IMAGE", "VIDEO"].includes(exerciseDetail?.type) &&
      exerciseDetailData?.qs
    ) {
      if (exerciseDetailData?.qs?.minWords) {
        if (wordCount < exerciseDetailData?.qs?.minWords) {
          overallScore = 0;
          reasons.push("MIN_WORDS");
        }
      }

      if (exerciseDetailData?.qs?.minDurationSec) {
        if (durationSecond < exerciseDetailData?.qs?.minDurationSec) {
          overallScore = 0;
          reasons.push("MIN_DURATION");
        }
      }

      if (exerciseDetailData?.qs?.containWords) {
        let wordMatchResult = exerciseDetailData?.qs?.containWords.map(
          (reqWord) => {
            let r = processResultData.NBest[0]?.Words?.filter(
              (word) =>
                word?.Word.localeCompare(reqWord, undefined, {
                  sensitivity: "base",
                }) === 0,
            ).filter((word) => word.AccuracyScore > 0);
            return { w: reqWord, count: r?.length ? r.length : 0 };
          },
        );
        let wordMatchCount = wordMatchResult.reduce(
          (accumulator, currentValue, index) => {
            const v = currentValue?.count > 0 ? 1 : 0;
            const returns = accumulator + v;
            return returns;
          },
          0,
        );

        console.log(777, wordMatchResult, wordMatchCount);
        let containWordsScore =
          (wordMatchCount * 100.0) /
          exerciseDetailData?.qs?.containWords.length;
        if (wordMatchCount < exerciseDetailData?.qs?.containWords.length) {
          reasons.push("CONTAIN_WORDS");
        }
        if (containWordsScore < overallScore) {
          overallScore = Math.round(
            (wordMatchCount * 100.0) /
              exerciseDetailData?.qs?.containWords.length,
          ).toFixed(2);
        }
      }
    }
    console.log(888, { score: overallScore, reasons: reasons });

    return { score: overallScore, reasons: reasons };
  }

  function calculateScoreLabel(value) {
    if (value > 55.0) {
      return "good";
    } else if (value > 20.0) {
      return "poor";
    } else {
      return "bad";
    }
  }

  function callHooks() {
    let slackData = {
      exerciseKey: exerciseDetail?.key,
      studentName: $userDetail.userName,
      overallScore: sensayData?.overallScore,
    };

    let messageData = { text: JSON.stringify(slackData) };

    postJsonFileGetText(
      "https://hooks.slack.com/services/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",
      {},
      {},
      messageData,
    )
      .then((data) => {
        console.log("postJsonFile", data);
      })
      .catch((error) => {
        console.error("error calling Slack Hook", error);
      });

    console.log(444, "sending message to window.parent.postMessage");
    window.parent.postMessage("RECORDING_DONE", "*");
  }

  function openLesson() {
    console.log("openLesson");
    const msg = {
      text: "PIN OK",
      pin: Number(loginDetail?.lessonId),
      userName: $userDetail.userName,
      loginToken: loginDetail?.loginToken,
      lessonId: Number(loginDetail?.lessonId),
      lessonKey: exerciseDetail?.lessonKey,
    };
    dispatch("openLesson", msg);
  }

  function nextButton() {
    if ($lessonPos === $lessonList?.length - 1) {
      openLesson();
    } else {
      const msg = {
        pin: $lessonList,
      };
      dispatch("openExercise", msg);
    }
  }

  function tryAgain() {
    dispatch("tryAgainExercise", {});
    //window.location.reload();
  }
</script>

{#if lastRecording}
  {#if processingStatus === "new"}
    <button class="btn btn-primary record" on:click={submitResult} type="button"
      ><i class="fas fa-check" />Submit</button
    >
  {:else if processingStatus === "processing"}
    <button class="btn btn-success submit" disabled type="button">
      <i class="fas fa-hourglass-half spin" />Submitting
    </button>
  {:else if processingStatus === "finished"}
    <button class="btn btn-success record" disabled type="button"
      ><i class="fas fa-check" />Finished</button
    >
    <button class="btn btn-primary record" on:click={tryAgain} type="button"
      ><i class="fas fa-repeat" />Try again</button
    >
    <!-- {#if loginDetail.lessonId}
      <button class="btn btn-primary record" on:click={openLesson} type="button"
        ><i class="fas fa-arrow-right" />Next Exercise</button
      >
    {/if} -->
    {#if loginDetail.lessonId}
      <button class="btn btn-primary record" on:click={nextButton} type="button"
        >{#if $lessonPos === $lessonList?.length - 1}<i
            class="fas fa-arrow-up"
          />To Lesson{:else}<i class="fas fa-arrow-right" />Next Exercise{/if}</button
      >
    {/if}
  {:else if processingStatus === "failure"}
    <div class="alert alert-danger" role="alert">
      Did you say something? Please check your microphone and try again.
    </div>
  {:else if processingStatus === "error"}
    <button class="btn btn-info" on:click={submitResult} type="button"
      ><i class="fas fa-check" />Resubmit</button
    >
  {/if}
{:else}
  <!-- <button class="btn btn-light" disabled type="button"><i
            class="fas fa-times" />No Data</button> -->
{/if}

<style>
  button {
    padding-right: 8px;
    font-size: 32px;
  }
  i {
    padding-right: 8px;
  }

  .spin {
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    50% {
      color: black;
    }
  }

  .record {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
  }

  .submit {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
  }
</style>
