<script>
  import Radar from "svelte-chartjs/src/Radar.svelte";
  import { onMount } from "svelte";

  export let resultData;
  export let exerciseType;
  export let exerciseDetail;

  let dataParsed = {
    pronunciation: 96,
    fluency: 75,
    accuracy: 88,
    completeness: 44,
    overall: 89,
  };

  let dataRadar = {};

  onMount(async () => {
    dataParsed.pronunciation = Number.parseFloat(resultData?.pronScore).toFixed(0);
    dataParsed.fluency = Number.parseFloat(resultData?.fluencyScore).toFixed(0);
    dataParsed.accuracy = Number.parseFloat(resultData?.accuracyScore).toFixed(0);
    dataParsed.completeness = Number.parseFloat(resultData?.completenessScore).toFixed(0);
    dataParsed.overall = Number.parseFloat(resultData?.overallScore).toFixed(0);

    console.log("dataParsed", dataParsed);

    if (exerciseType === "READ" || (exerciseType === "SELF_PRACTICE" && exerciseDetail?.selfPracticeText && exerciseDetail?.selfPracticeText.length > 0)){
      dataRadar = {
      labels: [
        "Overall ("+ dataParsed.overall+")",
        "Pronunciation ("+ dataParsed.pronunciation+")",
        "Fluency ("+ dataParsed.fluency+")",
        "Accuracy ("+ dataParsed.accuracy+")",
        "Completeness ("+ dataParsed.completeness+")",
      ],
      datasets: [
        {
          label: "Sensay Score",
          backgroundColor: "#047f7388",
          borderColor: "#ffcc07ff",
          data: [
            dataParsed.overall,
            dataParsed.pronunciation,
            dataParsed.fluency,
            dataParsed.accuracy,
            dataParsed.completeness,
          ],
        },
      ],
      options: {
        scale: {
          ticks: { beginAtZero: true, suggestedMin: 0, suggestedMax: 100 },
        },
      },
    };
    }else{
      dataRadar = {
      labels: [
        "Overall ("+ dataParsed.overall+")",
        "Pronunciation ("+ dataParsed.pronunciation+")",
        "Fluency ("+ dataParsed.fluency+")",
        "Accuracy ("+ dataParsed.accuracy+")"
      ],
      datasets: [
        {
          label: "Sensay Score",
          backgroundColor: "#047f7388",
          borderColor: "#ffcc07ff",
          data: [
            dataParsed.overall,
            dataParsed.pronunciation,
            dataParsed.fluency,
            dataParsed.accuracy
          ],
        },
      ],
      options: {
        scale: {
          ticks: { beginAtZero: true, suggestedMin: 0, suggestedMax: 100 },
        },
      },
    };
    }

    
  });
</script>

<div class="chart-container">
  {#key dataRadar}
    <Radar
      data={dataRadar}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        scale: {
          ticks: { beginAtZero: true, suggestedMin: 0, suggestedMax: 100 },
        },
      }}
    />
  {/key}
</div>

<style>
  .chart-container {
    min-width: 250px;
    min-height: 250px;
  }
</style>
