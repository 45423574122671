<script>
  import { router } from 'tinro'

  function goHome() {
    mixpanel.track('Student Practice Home Button Clicked')
    router.goto('/')
  }
</script>

<div class="row justify-content-center">
  <div class="col-auto" on:click|preventDefault={goHome} style="cursor:pointer">
    <svg
      width="208"
      height="52"
      viewBox="0 0 208 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.4317 4.21716C57.0746 3.41366 56.5839 2.67861 55.9809 2.04424C55.3432 1.40426 54.5973 0.885946 53.7787 0.514087C52.9817 0.17821 52.1276 0.00384832 51.2646 0.000834439H19.739C18.8545 -0.0136762 17.9769 0.161212 17.1635 0.514087C16.3717 0.900901 15.6483 1.41792 15.0227 2.04424C14.4345 2.68959 13.9468 3.42235 13.5766 4.21716C13.2457 5.02616 13.0739 5.89306 13.0709 6.76901V27.3134C13.0506 28.2148 13.2231 29.11 13.5766 29.9372C13.9279 30.7228 14.418 31.4365 15.0227 32.0429L15.0841 32.1053C15.661 32.7455 16.3713 33.247 17.1635 33.5731C17.9792 33.9183 18.8552 34.0929 19.739 34.0863H40.8821L49.001 40.984C49.1144 41.0752 50.1115 41.8714 51.0141 41.6843C51.3181 41.6486 51.5962 41.4937 51.7892 41.2526C51.8618 41.1477 51.9118 41.0284 51.9356 40.9025C52.0296 40.2665 52.0296 39.6198 51.9356 38.9838L51.2457 34.0671C52.1087 34.0641 52.9628 33.8898 53.7598 33.5539C54.5632 33.1813 55.3059 32.6864 55.962 32.0861C56.5523 31.4183 57.0413 30.6649 57.4128 29.8508C57.7405 29.0393 57.9106 28.1712 57.9138 27.2942V6.76901C57.9161 5.89485 57.7525 5.02843 57.4317 4.21716V4.21716Z"
        fill="#FFCC07"
      />
      <path
        d="M27.9999 29.6637C26.9591 29.6558 25.9307 29.4326 24.9776 29.0079C24.0246 28.5832 23.1668 27.9658 22.4566 27.1933C21.7199 26.3975 21.1529 25.456 20.7911 24.4276C20.4293 23.3992 20.2803 22.3061 20.3536 21.2166C20.3639 21.0702 20.4024 20.9272 20.4671 20.7959C20.5318 20.6645 20.6213 20.5474 20.7306 20.4512C20.8398 20.355 20.9667 20.2816 21.1039 20.2351C21.2412 20.1887 21.386 20.1701 21.5303 20.1805C21.6746 20.1909 21.8155 20.2301 21.9449 20.2957C22.0743 20.3614 22.1896 20.4522 22.2844 20.5631C22.3792 20.6741 22.4516 20.8028 22.4973 20.9421C22.5431 21.0814 22.5613 21.2285 22.5511 21.3749C22.4998 22.1564 22.6081 22.9403 22.8691 23.6774C23.1302 24.4144 23.5383 25.0889 24.0681 25.6584C24.5689 26.219 25.1804 26.6664 25.8627 26.9715C26.545 27.2767 27.283 27.4327 28.0286 27.4296C28.7742 27.4264 29.5108 27.2641 30.1905 26.9532C30.8703 26.6422 31.478 26.1897 31.9743 25.6248C32.4966 25.0585 32.8977 24.3885 33.1523 23.6571C33.4068 22.9256 33.5094 22.1486 33.4535 21.3749C33.4328 21.0791 33.5287 20.7871 33.7201 20.5631C33.9116 20.3392 34.1828 20.2015 34.4742 20.1805C34.7656 20.1596 35.0533 20.2569 35.274 20.4512C35.4947 20.6455 35.6302 20.9209 35.6509 21.2166C35.7206 22.2946 35.5735 23.3757 35.2184 24.3941C34.8634 25.4125 34.3079 26.347 33.5858 27.1406C32.8817 27.9192 32.0288 28.544 31.079 28.977C30.1292 29.4099 29.1024 29.642 28.0613 29.6589L27.9999 29.6637Z"
        fill="#00A094"
      />
      <path
        d="M44.4553 11.0906C44.2838 10.6776 44.0733 10.2824 43.8268 9.91061C43.5041 9.39204 43.1129 8.92077 42.6642 8.50997C42.1312 8.03723 41.5243 7.65789 40.8684 7.38754C40.0502 7.04547 39.173 6.87261 38.2882 6.87908H6.74367C5.8794 6.87995 5.02367 7.05269 4.22484 7.38754C3.40793 7.76235 2.66247 8.28031 2.02263 8.91769C1.42137 9.55243 0.932195 10.2875 0.576544 11.0906C0.24503 11.9011 0.0732434 12.7697 0.0708908 13.6473L0 37.5973C0.00965678 38.471 0.181197 39.335 0.505661 40.1444C1.21238 41.8055 2.51736 43.1301 4.15395 43.8474C4.95285 44.1826 5.80834 44.3569 6.67279 44.3607H7.30132L5.9781 49.2773C5.94975 49.402 5.91194 49.5795 5.88359 49.7906C5.84203 50.049 5.8325 50.3116 5.85523 50.5724C5.87017 50.9833 6.03923 51.3728 6.32781 51.6613C6.49917 51.8114 6.70595 51.9139 6.92798 51.9587C7.87313 52.1889 8.81829 51.3831 8.94116 51.2584L17.0458 44.3703H38.1842C39.0693 44.3746 39.9464 44.2001 40.7645 43.857C42.3733 43.1514 43.6561 41.8492 44.3513 40.2163C44.6893 39.386 44.8612 38.4957 44.857 37.5973L44.9279 13.6473C44.936 12.7719 44.7755 11.9034 44.4553 11.0906V11.0906ZM42.9383 37.5973C42.9408 38.2345 42.812 38.8651 42.5603 39.4488C42.3226 40.0201 41.9811 40.5408 41.5537 40.9838C41.1216 41.4526 40.5805 41.8039 39.98 42.0055C39.4284 42.2667 38.8258 42.3978 38.2173 42.3892H16.3275L7.95347 49.5267L9.90521 42.3748H6.69642C6.08791 42.383 5.48541 42.2518 4.9337 41.9911C4.34039 41.7744 3.8026 41.4253 3.36002 40.9694C2.91277 40.543 2.56833 40.0178 2.35344 39.4344C2.10166 38.8507 1.97289 38.2201 1.97537 37.5829L2.04626 13.6329C2.04377 12.9957 2.17255 12.365 2.42432 11.7813C2.66198 11.2101 3.00346 10.6894 3.43091 10.2464C3.88967 9.81122 4.42302 9.46495 5.00459 9.22468C5.5563 8.96397 6.15879 8.83281 6.7673 8.84095H38.2882C38.8968 8.83181 39.4995 8.96302 40.0509 9.22468C40.6396 9.4547 41.1741 9.80769 41.6198 10.2608C42.0473 10.7038 42.3888 11.2245 42.6264 11.7957C42.8782 12.3794 43.007 13.0101 43.0045 13.6473L42.9383 37.5973Z"
        fill="#00A094"
      />
      <path
        d="M129.453 8.82183C126.405 8.82183 123.967 9.95865 122.185 12.1795C121.491 9.65166 118.211 8.77866 115.267 8.82183H114.926L113.858 13.6185H114.718C115.323 13.6185 116.08 13.5946 116.5 14.0119C116.8 14.3789 116.946 14.8509 116.907 15.3262L116.949 35.1798H122.261V21.6147C122.261 16.5541 124.387 13.4123 127.818 13.4123C130.621 13.4123 132.043 15.379 132.043 19.2547V35.2134H137.355V18.7031C137.355 11.4169 133.272 8.82183 129.453 8.82183Z"
        fill="#00A094"
      />
      <path
        d="M102.355 8.80273C95.5929 8.80273 91.5571 13.7673 91.5571 22.0849C91.5571 30.623 95.6638 35.3238 103.116 35.3238C106.396 35.3238 109.586 34.4172 110.923 33.5634L111.155 33.4099L110.493 28.853C109.326 29.6877 106.103 31.1171 103.447 31.0691C102.403 31.0163 100.881 30.9684 99.4917 29.8987C98.1023 28.8291 97.1997 26.9056 96.9067 24.2098H111.878L111.925 23.8309C112.048 22.6886 112.111 21.5406 112.114 20.3916C112.114 10.3089 105.999 8.80273 102.355 8.80273ZM96.9114 20.2141C97.1477 16.8229 98.3291 13.019 102.332 13.019C106.112 13.019 106.93 16.2808 106.93 19.015C106.93 19.4946 106.93 19.9167 106.93 20.2285L96.9114 20.2141Z"
        fill="#00A094"
      />
      <path
        d="M202.424 8.68316L196.823 29.1124L190.453 8.68316H184.782L193.79 36.6049C193.883 36.8187 193.931 37.0499 193.931 37.2836C193.931 37.5174 193.883 37.7486 193.79 37.9624C193.662 38.2214 193.246 38.8066 189.905 39.0944C188.87 39.1855 188.015 39.2095 187.414 39.2095C187.77 40.7572 188.123 42.3066 188.473 43.8575C189.491 43.8912 190.51 43.8431 191.521 43.7136C192.486 43.6218 193.437 43.4191 194.357 43.1092C197.495 42.0252 198.827 39.7515 199.805 36.4802L208 8.65918L202.424 8.68316Z"
        fill="#00A094"
      />
      <path
        d="M182.273 30.2202C181.978 29.8582 181.837 29.3925 181.88 28.9251V16.6695C181.88 11.7097 178.506 8.86523 172.618 8.86523C170.189 8.86523 166.63 9.34491 163.941 10.6496L163.667 10.7839L164.22 15.619L164.778 15.3024C166.196 14.4918 169.362 13.1151 172.126 13.1151C175.184 13.1151 176.611 14.3863 176.611 17.1156V19.2789L173.544 19.3269C166.092 19.4276 162.618 22.0322 162.618 27.5293C162.618 32.4459 165.624 35.3863 170.652 35.3863C174.371 35.3863 176.271 33.8514 177.174 32.681C178.209 35.0553 181.469 35.3911 183.208 35.3911H183.52H183.856L184.872 30.5944H184.021C183.43 30.604 182.684 30.628 182.273 30.2202ZM176.559 23.2842V26.1622C176.559 31.0549 171.507 31.1076 171.295 31.1076C169.749 31.1076 168.086 29.9037 168.086 27.2703C168.086 24.5217 169.797 23.3753 173.998 23.3178L176.559 23.2842Z"
        fill="#00A094"
      />
      <path
        d="M81.1369 19.2642C77.8714 18.1609 76.7136 17.4318 76.7136 15.5371C76.7136 14.8944 76.7136 13.0524 80.1682 12.9325C81.0483 12.8487 81.9357 12.9701 82.7626 13.2875C83.4431 13.6232 83.5235 14.3715 83.4715 15.3644L83.4431 16.2279L88.1925 15.3309V14.9903C88.2587 13.6568 88.2161 12.261 87.4884 11.1241L87.3513 10.9275C86.5432 9.75228 85.2814 9.41172 84.2796 9.12391C83.4143 8.89314 82.527 8.75805 81.6332 8.72099C81.6332 8.72099 81.0141 8.68262 80.447 8.68262C74.6579 8.68262 71.3357 11.3256 71.3357 15.9401C71.3357 21.6961 76.6002 23.1351 79.7476 23.9794C81.7513 24.5118 83.8023 25.38 83.8023 27.7448C83.8023 29.7546 82.2191 30.8579 79.7192 30.8291C78.0983 30.8291 76.3592 30.4789 75.655 29.8697C74.9509 29.2605 74.8753 28.9104 74.9887 27.5721L75.0313 27.0397L70.2582 27.6728L70.2204 28.0134C69.9699 30.359 70.1354 32.7526 73.7978 34.2491C75.8175 34.8679 77.9122 35.1989 80.0217 35.2325C86.0234 35.2325 89.3314 32.4935 89.3314 27.5241C89.3503 22.4396 86.0848 20.9574 81.1369 19.2642Z"
        fill="#00A094"
      />
      <path
        d="M151.437 19.2642C148.177 18.1609 147.019 17.4318 147.019 15.5371C147.019 14.8944 147.019 13.0524 150.473 12.9325C151.354 12.849 152.241 12.9704 153.068 13.2875C153.748 13.6232 153.829 14.3715 153.777 15.3644L153.748 16.2279L158.474 15.3309V14.9903C158.54 13.6568 158.474 12.261 157.765 11.1241L157.628 10.9275C156.825 9.75228 155.563 9.41172 154.561 9.12391C153.696 8.89347 152.809 8.75838 151.915 8.72099C151.915 8.72099 151.296 8.68262 150.729 8.68262C144.935 8.68262 141.613 11.3256 141.613 15.9401C141.613 21.6961 146.882 23.1351 150.029 23.9794C152.033 24.5118 154.079 25.38 154.079 27.7448C154.079 29.7546 152.501 30.8579 150.001 30.8291C148.38 30.8291 146.641 30.4789 145.937 29.8697C145.232 29.2605 145.157 28.9104 145.266 27.5721L145.313 27.0397L140.559 27.6728L140.526 28.0134C140.275 30.359 140.441 32.7526 144.103 34.2491C146.12 34.8569 148.209 35.1781 150.313 35.2037C156.319 35.2037 159.622 32.4648 159.622 27.4954C159.632 22.4396 156.39 20.9574 151.437 19.2642Z"
        fill="#00A094"
      />
    </svg>
  </div>
</div>
