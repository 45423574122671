<script context="module">
  export function convertSecToMin(timeInSec) {
    let mins = ~~((timeInSec % 3600) / 60)
    let secs = ~~timeInSec % 60
    let formattedTime =
      (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '') + secs
    return formattedTime
  }

  export function cleanCharactersForSSML(text) {
    text = text.replaceAll('&', '&amp;')
    text = text.replaceAll('<', '&lt;')
    text = text.replaceAll('>', '&gt;')
    return text
  }

  export function cleanCharactersForSpeechToText(text) {
    text = text.replaceAll('&', 'and')
    text = text.replaceAll('<', 'less than')
    text = text.replaceAll('>', 'greater than')
    text = text.replaceAll('\n', ' ')

    return text
  }
</script>
