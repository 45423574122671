<script>
  let instructionsOpen = false

  export let exerciseDetail

  function getSelectionHtml() {
    var html = ''
    if (typeof window.getSelection != 'undefined') {
      var sel = window.getSelection()
      if (sel.rangeCount) {
        var container = document.createElement('div')
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents())
        }
        html = container.innerHTML
      }
    } else if (typeof document.selection != 'undefined') {
      if (document.selection.type == 'Text') {
        html = document.selection.createRange().htmlText
      }
    }
    return html
  }

  function toggleInstuction() {
    if (getSelectionHtml()) {
      return
    }
    instructionsOpen = !instructionsOpen
  }
</script>

<div class="row justify-content-center">
  <div class="col-md-auto">
    <div class="instructions" on:click={toggleInstuction}>
      {#if instructionsOpen}
        {#if exerciseDetail.type === 'READ'}
          <div>
            <strong>Instructions:</strong> Record yourself reading the following
            passage. Click "Record" and start speaking. Click "Stop" when finished,
            and click "Submit" to see your scores.
          </div>
          <div style="margin-top: 1em;">
            <strong>Optional:</strong> Click
            <i class="fas fa-volume-up" style="font-size: 1em" /> to listen to the
            entire text. You can also click a specific word to listen to it.
          </div>
        {:else if exerciseDetail.type === 'QUESTION'}
          <div>
            <strong>Instructions:</strong> Please answer the following question.
            You can press <i class="fas fa-volume-up" style="font-size: 1em" /> to
            read the question.
          </div>
        {:else if exerciseDetail.type === 'QUESTION_FIX_ANSWER'}
          <div>
            <strong>Instructions:</strong> Please answer the following question.
            You can press <i class="fas fa-volume-up" style="font-size: 1em" /> to
            read the question.
          </div>
        {:else if exerciseDetail.type === 'VIDEO'}
          <div>
            <strong>Instructions:</strong> Watch the embedded video and follow the
            instructions inside.
          </div>
        {:else if exerciseDetail.type === 'SELF_PRACTICE'}
          <div>
            <strong>Instructions:</strong> Input the text you would like to practice
            speaking in the text box below. Click "Record" to start speaking. Click
            "Stop" when you are finished, and click "Submit" to see your scores.
          </div>
          <div style="margin-top: 1em;">
            <strong>Note:</strong> There is a 30 second maximum time limit.
          </div>
        {:else if exerciseDetail.type === 'IMAGE'}
          <div>
            <strong>Instructions:</strong> Check the embedded image and follow the
            instructions inside.
          </div>
        {:else if exerciseDetail.type === 'READ_IMAGE'}
          <div>
            <strong>Instructions:</strong> Check the embedded image and follow the
            instructions inside.
          </div>
        {:else}
          <strong>Instructions:</strong> ---
        {/if}
        <div style="margin-top: 1em;">
          Please try not to pause for longer than 2 seconds when speaking. The
          text output may not be accurate if you pause. If your recording was
          cut off, please try again with no pauses.
        </div>
      {:else}
        <div style="text-align: center; cursor: pointer;">Instructions</div>
      {/if}
    </div>
  </div>
</div>

<style>
  .instructions {
    border-color: #ffcc0c;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    padding: 1em;
    font-size: 0.8em;
  }
  @media (min-width: 768px) {
    .instructions {
      padding: 1em;
      font-size: 1em;
    }
  }
</style>
