<script>
  import { router } from "tinro";
  import { createEventDispatcher, onMount } from "svelte";
  import { BASE_URL } from "./DataStore";
  import BannerWithLogo from "./exercise/BannerWithLogo.svelte";
  import { lessonList, userDetail } from "./DataStore";

  export let loginDetail;
  export let lessonId;
  export let lessonPin;

  let lessonStatus = "NEW";
  let lessonData;
  let errorMsg = undefined;
  let isLessonDone = false;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    loadData();
  });

  async function loadData() {
    lessonStatus = "LOADING";

    if (!lessonId) {
      await fetch(BASE_URL + `public/api/lessons?key=${lessonPin}`)
        .then((response) => {
          if (!response.ok) {
            console.error("response error");
            throw new Error("Network response was not ok", {
              cause: { url: response?.url, status: response?.status },
            });
          }
          console.log("response ok");
          return response.json();
        })
        .then((data) => {
          lessonId = data.id;
        })
        .catch((error) => {
          console.error("catch error", error);
          errorMsg = "Missing PIN";
          try {
            mixpanel.track("MissingPIN", {
              pin: pin,
              errorMsg: error.toString(),
              cause: JSON.stringify(error.cause),
            });
            Bugsnag.notify(new Error(error.toString()));
          } catch (error) {
            console.log("error mixpanel");
          }
        });
    }

    fetch(
      BASE_URL +
        `public/api/lessons/` +
        lessonId +
        `/overview?studentToken=` +
        $userDetail?.loginToken +
        "&studentLogin=" +
        $userDetail.userName,
    )
      .then((response) => {
        if (response.status == 403) {
          console.error("response error");
          throw new Error("Lesson not available");
        }
        if (!response.ok) {
          console.error("response error");
          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        lessonData = data;
        $lessonList = data?.exerciseList;
        isLessonDone =
          data?.exerciseList?.length > 0 &&
          data?.exerciseList
            .map((v) => (v?.overallScore > 0 ? 1 : 0))
            .reduce((r, v) => r + v) === data?.exerciseList.length;
        console.log("lessonData", lessonData);
        lessonStatus = "OK";
      })
      .catch((error) => {
        console.error("catch error", error);

        errorMsg = "Error loading lesson";
        if (error?.message === "Lesson not available") {
          errorMsg = "This lesson is not available";
        }
        lessonData = undefined;
        lessonStatus = "ERROR";
      });
  }

  function openExercise(exerciseKey) {
    console.log("openExercise", exerciseKey);
    const msg = {
      text: "PIN OK",
      pin: exerciseKey,
      lessonId: lessonId,
      lessonPin: lessonPin,
    };
    dispatch("openExercise", msg);
  }
</script>

<div class="container">
  <BannerWithLogo />

  <div class="row align-items-center">
    <div class="col">
      <div class="lesson-title">
        {#if lessonData?.lessonName}
          {lessonData.lessonName}
        {/if}
      </div>
    </div>
    <div class="col-auto lesson-detail-box">
      <div class="lesson-detail float-right">
        <p>Class: {lessonData?.lessonKey}</p>
        <div>{$userDetail?.userName}</div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-auto">
      <div>
        {#if lessonStatus === "OK"}
          <pre class="lesson-description">{lessonData?.lessonDescription}</pre>
        {/if}
      </div>
    </div>
  </div>

  <div class="card-group">
    {#if lessonStatus === "NEW"}
      Loading ...
    {:else if lessonStatus === "LOADING"}
      Loading ...
    {:else if lessonStatus === "ERROR"}
      <div class="alert alert-danger" role="alert">
        {errorMsg}
      </div>
    {:else}
      {#each lessonData?.exerciseList as l, i}
        <div
          class="cardHover"
          class:done={l.overallScore != null}
          style="min-width: 240px; max-width: 33%"
          on:click={(x) => {
            openExercise(l.exerciseKey);
          }}
        >
          <div class="card-body">
            <h4 class="card-title">{l.exerciseTitle}</h4>
            <p class="card-text">
              {#if l.overallScore}Score: {Number.parseFloat(
                  l?.overallScore,
                ).toFixed(0)}
              {:else}<br />
              {/if}
            </p>
            <div>
              <small>
                {l.exerciseType}
              </small>
            </div>
          </div>
        </div>
      {:else}
        This lesson doesn't have any available exercise.
      {/each}
    {/if}
  </div>

  {#if isLessonDone}
    <div class="row justify-content-center mt-4">
      <div class="lesson-title">
        <div>Great, you're done! 🎉</div>
      </div>
    </div>
  {/if}
</div>

<style>
  @media (max-width: 768px) {
    .lesson-detail-box {
      display: none;
    }
  }

  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lesson-title {
    font-weight: 600;
    font-size: large;
  }

  .lesson-detail {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }

  .lesson-description {
    font-family: "Roboto Slab";
    white-space: pre-wrap;
  }

  .cardHover {
    cursor: pointer;
    margin: 0.5em;
    border: 2px;
    border-color: #047f73ff;
    border-style: solid;
    border-radius: 0.35rem;
    box-shadow: 2px 2px 1px rgb(192, 187, 187);
    background-color: #eff5f4;
  }

  .cardHover:hover {
    border-color: #ffcc07ff;
    box-shadow: 0px;
  }

  .done {
    color: #f4f4f4;
    border-color: #00a094ff;
    background-color: #00a094ff;
    box-shadow: none;
  }
</style>
